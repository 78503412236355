/* eslint-disable */
import { ReactElement } from 'react';
import TagManager from 'react-gtm-module';
import { Link } from 'react-router-dom';
import {
  Plan,
  PlanHeader
} from './styles';

type props = {
  plan: number
  oldValue?: string
  newValue: string
  parcel?: string
  parcelValue?: string
  children?: ReactElement
  setOpen: Function
  isMain?: boolean
};
export default function SitePlan(props: props): ReactElement {
  const idPredictaOne = process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID as string;
  const idPredictaInfinity = process.env.REACT_APP_PREDICTA_PRODUCT_INFINITY_ID as string;
  const idGeneticConsultation = process.env.REACT_APP_PREDICTA_GENETIC_CONSULTATION as string;

  function setIdPlan(idPlan: number) {
    if (idPlan === 1) {
      localStorage.setItem('selectedProduct', idPredictaOne);
      localStorage.setItem('productSelected', '1');
    }
    if (idPlan === 2) {
      localStorage.setItem('selectedProduct', idPredictaInfinity);
      localStorage.setItem('productSelected', '2');
    }
    if (idPlan === 3) {
      localStorage.setItem('selectedProduct', idGeneticConsultation);
      localStorage.setItem('productSelected', '3');
    }

  }

  function labelCard(plan: number) {
    switch (plan) {
      case 1:
        return "Predicta One";
      case 2:
        return "Predicta Infinity";
      default:
        return "Consulta Genética"
    }
  }

  return (
    <Plan isMain={props.isMain ?? false}>
      <PlanHeader isMain={props.isMain ?? false} >
        <h3>
          <label className={props.isMain ? "blue" : "greenLabel"}> {labelCard(props.plan)}</label>
        </h3>

        <div className='background-description'>
          {props.plan === 3 && (
            <>
              <b>Particular</b>
              <div>
                <h6>R$</h6>
                <h5> {props.parcelValue}</h5>
              </div>            
              <br/>
            </>

          )}
          {props.parcel && (
            <>
              <b>Em Até {props.parcel}x de</b>
              <div>
                <h6>R$</h6>
                <h5> {props.parcelValue}</h5>
              </div>
              <b>sem juros</b>                                
            </>
          )}
          {props.oldValue && (
            <h4 className="old-value">R$ {props.oldValue}</h4>
          )}
                    
          <h2>Total: R$ {props.newValue} </h2>
          {/* <small>Mensais</small> */}

        </div>

      </PlanHeader>

      <ul>
        {props.children}
      </ul>

      <div className='space' />

      <div>
        {/*
        <button id={'btn-01' + props.plan} onClick={() => { if (props.plan === 2) { window.location.href = '/infinity'; } else { window.location.href = '/one'; } }} className="btn-outline">Saiba mais</button>
        <Link id={'btn-02' + props.plan}
          className="btn"
          to={{
            pathname: 'contratar',
            state: props.plan
          }}
        >Contratar agora
        </Link> */}

        {props.plan !== 3 &&
          (
            <div>
              {props.plan === 1
                ? (
                  <Link className="btn-outline" to="/one">Saiba mais</Link>
                )
                : (
                  <Link className="btn-outline" to="/infinity">Saiba mais</Link>
                )}

            </div>
          )
        }

        <div onClick={() => {
          const tagManagerArgs = {
            gtmId: 'GTM-TDPD3K8',
            dataLayer: {
              event: 'Einstein-Predicta',
              categoria: 'Contratar-Agora',
              acao: 'step-1',
              rotulo: props.plan === 1 ? 'Predicta One' : 'Predicta Infinity',
              value: props.plan === 1 ? '2754.00' : '16640.00'
            }
          };
          TagManager.initialize(tagManagerArgs);
        }}>

          {
            props.plan === 3 ? (
              <Link className="btn"
                target="_blank"
                to={{ pathname: "https://agendamento.einstein.br/?medicos_einstein=1" }}
              >Agendar minha consulta
              </Link>
            ) : <Link className="btn"
              to={{
                pathname: 'contratar',
                state: props.plan
              }}
              onClick={() => {
                setIdPlan(props.plan);
              }}
            >Quero este plano
            </Link>
          }

        </div>
      </div>
    </Plan >
  );
}
