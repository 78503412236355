import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../ui/Footer';
import Header from '../../ui/Header';
import HeaderMobile from '../../ui/HeaderMobile';
// import Card from '../../components/SiteInfoCard';
import FactImg from '../../assets/Site/FACT.png';
import Doutor1 from '../../assets/Site/Imagem1.png';
import Doutor2 from '../../assets/Site/Imagem2.png';
import Doutor3 from '../../assets/Site/Imagem3.png';
import Doutor5 from '../../assets/Site/Imagem5.png';
import WestchesterImg from '../../assets/Site/Westchester.png';
import BannerImg from '../../assets/Site/banner-predicta.jpg';
import JciImg from '../../assets/Site/jci.png';
import ForWhoImg from '../../assets/Site/para-quem.jpg';
import AboutImg from '../../assets/Site/predicta-about.jpg';
import Plan from '../../components/Plan';
import SecurityCard from '../../components/SiteSecCard';
import Accordion from '../../ui/Accordion';
import Banner from '../../ui/Banner';
import ModalPredicta from '../../ui/ModalPredicta';

import {
  About,
  AccordionWrapper,
  CompromiseSection,
  Content,
  FaqSection,
  // CardsSection,
  // CardsWrapper,
  ForWho,
  PlansSection,
  PlansWrapper,
  SecuritySection
} from './styles';

import {
  // IconScheduleCheckupGenetic,
  // IconBaby,
  // IconMedicine,
  // IconAtom,
  // IconApple,
  // IconTree,
  // IconBrokenGene,
  // IconVirus,
  // IconHeart,
  // IconMedBottle,
  // IconFace,
  // IconLungs,
  IconCheck,
  IconDoc,
  IconGene,
  IconLab,
  LogoEinstein
} from '../../assets/icons';

import Slider from 'react-slick';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import { FIRST_ITEMS, Item, ScheduleRootState } from '../../store/redux';
import ButtonWhatsapp from '../../ui/ButtonWhatsapp';
import DescriptionCard from '../../ui/DescriptionCard';
import { List, ListUnits } from '../../ui/ModalPredicta/styles';
import unitsPredictaByUF from '../../utils/unitsByUF';
import { NextArrow, PrevArrow } from '../Dashboard/styles';

export default function Home(): ReactElement {
  const dispatch = useDispatch();
  const accordionItems = useSelector<ScheduleRootState, Item[] | undefined>(
    (scheduleReducer) => scheduleReducer.filtered
  );
  const [allFaq, setAllFaq] = useState(false);
  const [open, setOpen] = useState(false);

  const [width, setWindowWidth] = useState(0);
  const showDesktopComponent: boolean = width > 768;

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    centerPadding: '0px',
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow className="next-arrow" />,
    prevArrow: <PrevArrow className="prev-arrow" />
  };

  useEffect(() => {
    dispatch({ type: FIRST_ITEMS, number: 5 });
  }, []);

  function allItems(): void {
    setAllFaq(!allFaq);
    dispatch({ type: FIRST_ITEMS, number: allFaq ? 5 : 0 });
  }

  return (
    <>
      {showDesktopComponent ? <Header /> : <HeaderMobile />}

      <Content>
        <Banner
          id="banner"
          image={BannerImg}
          productName="Predicta"
          title="O futuro da sua saúde começa nos seus genes"
          description="Ter um mapa genético é a oportunidade de prevenir doenças e monitorar a sua saúde."
          textButton="CONTRATE AGORA"
          linkButton="#contratar"
        />

        <About id="como-funciona">
          <img src={AboutImg} />
          <div>
            <h2>
              O que é o <span className="green mb-50">Predicta</span>
            </h2>
            <p>
              Nossos genes contam histórias. De onde você veio e, principalmente, para onde você está caminhando.
              Você sabia que um exame genético pode identificar alterações no DNA que indicam predisposição a desenvolver algumas doenças?
              O Predicta chegou para inovar através de uma nova tecnologia que une testes completos com a qualidade e segurança Einstein que você já conhece.
            </p>
            <p>
              São dois tipos de exames que podem ajudar seu médico a montar um
              check-up preventivo personalizado, e até mesmo guiar a escolha de tratamentos,
              levando em conta os medicamentos que são mais eficazes para o seu organismo.
              Seu corpo fala e nós te ajudamos a ouvir!
            </p>
          </div>
        </About>

        {/* <CardsSection id="diferenciais">
          <div>
            <h2>O que é analisado no <label className="green">Predicta</label></h2>
            <p>
              <strong>Com apenas uma amostra orgânica coletada</strong>, o sequenciamento é feito  em poucos dias, revelando múltiplas informações sobre <br /> a sua ancestralidade, metabolismo e saúde.
            </p>
          </div>
          <CardsWrapper>
            <Card
              icon={<IconScheduleCheckupGenetic />}
              title="Resultados Clinícos"
              description="Interpretação de genes relacionados a condições acionáveis que têm impacto na saúde de um indivíduo."
            />

            <Card
              icon={<IconBaby />}
              title="Análise de Portador"
              description="Análise de mais de 200 doenças que podem ser transmitidas às crianças."
            />

            <Card
              icon={<IconMedicine />}
              title="Análise de Farmacogenômico"
              description="Estudo do metabolismo de mais de 150 medicamentos."
            />

            <Card
              icon={<IconAtom />}
              title="Doenças multifatoriais"
              description="Condições em que fatores de risco genéticos e ambientais estão envolvidos."
            />

            <Card
              icon={<IconApple />}
              title={<>Traços <br /> Genéticos</>}
              description="Características individuais influenciadas por genes e ambiente."
            />

            <Card
              icon={<IconTree />}
              title="Análise de Ancestralidade"
              description="Análise de variantes no DNA para estabelecer a origem ancestral."
            />

          </CardsWrapper>
        </CardsSection> */}

        <ForWho>
          <div>
            <h2 className="mb-50">
              Para <span className="green">quem é</span>
            </h2>
            <p>
              Geralmente, os exames genéticos são solicitados após suspeita de
              síndromes, doenças hereditárias ou condições genéticas. Nesses
              casos, os especialistas escolhem o teste genético mais apropriado
              de acordo com a hipótese diagnóstica do paciente. O Predicta é
              diferente porque é voltado para pessoas saudáveis, que desejam se
              prevenir, quando possível, de algumas doenças de origem genética
              que possam se desenvolver no futuro.
            </p>
            <p>
              Recomendamos que os resultados sejam sempre avaliados junto com um
              especialista de sua confiança.
            </p>
          </div>
          <img src={ForWhoImg} />
        </ForWho>

        {/* <CardsSection>
          <div>
            <h2>A importância do <label className="green">sequenciamento genético</label></h2>
          </div>
          <CardsWrapper>
            <Card
              icon={<IconBrokenGene />}
              title="17%"
              description="17% das pessoas têm variantes genéticas que aumentam o risco de doenças."
            />

            <Card
              icon={<IconVirus />}
              title="20%"
              description="Até 20% dos casos de câncer estão relacionados a fatores hereditários"
            />

            <Card
              icon={<IconHeart />}
              title="20%"
              description=" Cerca de 20% das mortes cardíacas súbitas são devidas a anomalias genéticas."
            />

            <Card
              icon={<IconMedBottle />}
              title="5%"
              description="5% dos pacientes são hospitalizados por reações adversas a medicamentos."
            />

            <Card
              icon={<IconFace />}
              title="86%"
              description="86% das pessoas são portadoras assintomáticas de uma doença monogênica que pode ser transmitida aos filhos se o parceiro for portador da mesma doença."
            />

            <Card
              icon={<IconLungs />}
              title="6%"
              description="6% da população tem um risco aumentado de trombose hereditária."
            />
          </CardsWrapper>
        </CardsSection> */}
        <YoutubeEmbed
          videoUrl={'https://www.youtube.com/embed/TacoGdI65OQ'}
          videoTitle={'Einstein Predicta'}
        />

        <PlansSection id="contratar">
          <h2>
            Planos <span className="green">Predicta</span>
          </h2>
          <PlansWrapper>
            <Plan
              plan={1}
              newValue="2.754,00"
              parcel="6"
              parcelValue="459,00"
              modalChildren={
                <>
                  <li>
                    <p>
                      É simples, rápido e fácil! O Predicta One foi desenvolvido
                      por especialistas do Hospital Israelita Albert Einstein
                      para que você conheça algumas das informações contidas nos
                      seus genes. Nosso compromisso é com sua saúde, por isso
                      nosso foco são as doenças genéticas e hereditárias.
                    </p>
                  </li>
                  <li>
                    <p>
                      O exame irá proporcionar que você saiba se tem risco
                      aumentado de desenvolver alguma doença genética. Com essa
                      informação, você pode, juntamente com seu médico, adotar
                      um check-up personalizado, com exames voltados para a
                      detecção precoce.
                    </p>
                  </li>
                  <li>
                    <p>
                      Para realizar o Predicta One, basta adquirir o exame
                      através do nosso site. Você receberá o kit completo para
                      colher sua saliva e enviar de volta para o nosso
                      laboratório. O resultado será disponibilizado através do
                      nosso site, com liberação de acesso através de login e
                      senha. O teste não exige solicitação médica, mas
                      recomendamos fortemente que o resultado seja avaliado por
                      um especialista de sua preferência.
                    </p>
                  </li>
                  <li>
                    Confira abaixo a lista de doenças que podem ter seus riscos
                    aumentados detectados pelo Predicta One:
                  </li>
                  <li>
                    <b>Tipos de Câncer:</b>
                  </li>
                  <li>
                    <p>
                      Cerebral
                      <br />
                      Endométrio/Útero
                      <br />
                      Hematológico
                      <br />
                      Mama
                      <br />
                      Melanoma
                      <br />
                      Ovário
                      <br />
                      Pâncreas
                      <br />
                      Próstata
                      <br />
                      Sarcomas
                      <br />
                      Tireoide
                      <br />
                      Tumores de Pele
                      <br />
                      Tumores Endocrinológico
                      <br />
                      Tumores Gastrointestinais
                      <br />
                      Tumores Renais
                      <br />
                    </p>
                  </li>
                  <li>
                    <b>Doenças cardíacas:</b>
                  </li>
                  <li>
                    <p>
                      Aortopatias
                      <br />
                      Arritmias
                      <br />
                      Cardiopatia congênita
                      <br />
                      Doença de Depósito Lisossomal
                      <br />
                      Doença Neuromuscular
                      <br />
                      Epiteliomas Escamosos Múltiplos Auto-Remitentes
                      <br />
                      Formas genéticas de pressão alta e colesterol alto
                      <br />
                      Hipertensão Arterial Pulmonar
                      <br />
                      Hipobetalipoproteinemia Familiar
                      <br />
                      Miocardiopatias
                      <br />
                      Telangiectasia Hemorrágica Hereditária
                      <br />
                      Trombofilia hereditária
                      <br />
                    </p>
                  </li>
                  <li>
                    <b>Outras doenças:</b>
                  </li>
                  <li>
                    <p>
                      Deficiência de Alfa-1-Antitripsina
                      <br />
                      Deficiência de Ornitina Transcarbamilase
                      <br />
                      Doença de Wilson
                      <br />
                      Hemocromatose Hereditária
                      <br />
                      Paralisia Periódica Hipocalêmica
                      <br />
                      Susceptibilidade à Hipertermia Maligna
                      <br />
                    </p>
                  </li>
                </>
              }
            >
              <>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Receba seu kit de coleta de saliva em qualquer lugar do Brasil.
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>Serão avaliadas alterações em 153 genes.</div>
                </li>
                {/* <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>Avalia o risco de formação de coágulos sanguíneos</div>
                </li> */}
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    O teste avalia risco aumentado de origem genética para 15 tipos de câncer, 12 doenças cardiovasculares e outras 7 doenças genéticas.
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Laudo fornecido sem burocracia para ser avaliado pelo médico de sua preferência.
                  </div>
                </li>
              </>
            </Plan>
            <Plan
              plan={2}
              newValue="16.640,00"
              parcel="10"
              parcelValue="1.664,00"
              isMain={true}
              modalChildren={
                <>
                  <li>
                    <p>
                      Uma solução completa em genética. Assim pode ser definido
                      o Predicta Infinity. Muito mais que um exame, é oferecido
                      um acompanhamento com especialistas, além de uma reanálise
                      do exame.
                    </p>
                  </li>
                  <li>
                    <p>
                      Por ser uma avaliação mais completa, o Predicta Infinity
                      é feito a partir de uma amostra de sangue colhida do paciente
                      em nossos laboratórios do Hospital Israelita Albert Einstein
                      <a href="">(veja aqui as unidades)</a>. Todo o genoma será avaliado para
                      detecção de alterações capazes de causar doenças.
                    </p>
                  </li>
                  <li>
                    <p>
                      Tudo começa com uma consulta pré-teste com médico geneticista e,
                      após os resultados, uma nova avaliação profissional é feita com
                      discussão dos resultados e suas implicações. Como novas descobertas
                      acontecem na área da genética periodicamente, os laudos com alterações
                      serão revisados e tais resultados serão atualizados no segundo, terceiro,
                      quarto e quinto anos do acompanhamento com um médico geneticista.<br></br><br></br>

                      <strong>Importante:</strong> O teste não exige solicitação médica.<br></br><br></br>

                      Temos uma equipe especializada para tirar suas dúvidas, clique no ícone
                      do Whatsapp e escolha a opção Predicta.
                    </p>
                  </li>
                  <li>
                    <p>
                      O Predicta Infinity identifica risco aumentado de origem
                      genética para:
                    </p>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>22 tipos de câncer</div>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>12 doenças cardiovasculares</div>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>
                      Avaliação de risco de formação de coágulos sanguíneos
                    </div>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>
                      Genes recessivos, com implicações para transmissão a cada
                      filho
                    </div>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>
                      Distúrbios metabólicos, do sistema imunológico e
                      neurológico
                    </div>
                  </li>
                </>
              }
            >
              <>
                <li>
                  <div className="blue-check-default">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Coleta de sangue em nossos laboratórios.
                  </div>
                </li>
                <li>
                  <div className="blue-check-default">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Sequenciamento do genoma completo com avaliação de risco e condições genéticas em 563 genes.
                  </div>
                </li>
                {/* <li>
                  <div className='blue-check'>
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>

                    <p>
                      Identifica risco aumentado de vários tipos de cânceres, como mama e ovário.
                      O teste avalia risco aumentado de origem genética para:
                    </p>

                    <p>
                      22 tipos de câncer
                    </p>
                    <p>
                      12 doenças cardiovasculares
                    </p>
                    <p>
                      Avalia o  risco de formação de coágulos sanguíneos
                    </p>
                    <p>
                      Genes recessivos de doenças genéticas
                    </p>
                    <p>
                      Distúrbios metabólicos, do sistema imunológico e neurológico
                    </p>
                  </div>
                </li> */}
                <li>
                  <div className="blue-check-default">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Consulta pré e pós-teste, com avaliação do histórico clinico e esclarecimento dos resultados.
                  </div>
                </li>
                <li>
                  <div className="blue-check-default">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Durante 4 anos, o resultado é atualizado, juntamente com a orientação de um geneticista.
                  </div>
                </li>
                <li>
                  <div className="blue-check-default">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Análise de alterações genéticas para verificar quais os medicamentos são mais efetivos, ou contra-indicados, para o seu organismo.
                  </div>
                </li>
              </>
            </Plan>
            <Plan
              plan={3}
              newValue="884,00"
              parcelValue="884,00"
              modalChildren={
                <></>
              }
            >
              <>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Profissionais altamente especializados que trabalham com indivíduos e famílias buscando compreender o impacto dos genes na saúde humana.
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Te ajudar a compreender riscos de doenças hereditárias, investigar histórico clínico familiar e auxiliar na escolha de testes a serem realizados.
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Clique no botão abaixo, faça uma busca por "Genética médica" , escolha um especialista e agende sua consulta.
                  </div>
                </li>
              </>
            </Plan>
          </PlansWrapper>
        </PlansSection>
        <h2 style={{ textAlign: 'center', alignItems: 'center', paddingTop: '50px' }}>Segurança e confiabilidade Einstein</h2>
        <SecuritySection id="diferenciais">
          <ModalPredicta
            open={open}
            setOpen={setOpen}
            title="NOSSOS LABORATÓRIOS"
            children={
              <>
                <List>
                  <ul>
                    <li>
                      <p>
                        Utilizamos tecnologia de sequenciamento de segunda
                        geração para realizar a análise do DNA. O processo é em
                        grande parte automatizado, o que minimiza o risco de
                        falhas humanas, aumentando a assertividade.
                      </p>
                    </li>
                    <li>
                      <p>
                        A sua amostra de sangue deverá ser coletada em nossos
                        laboratórios do Hospital Israelita Albert Einstein.
                        Veja as unidades:
                      </p>
                    </li>
                  </ul>
                  <ListUnits>
                    {
                      Object.keys(unitsPredictaByUF).map(key => (
                        <div>
                          <h3>{unitsPredictaByUF[key].uf}</h3>
                          <ul>
                            {unitsPredictaByUF[key].units.map((unity: any) => (
                              <li>
                                <p>{unity}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))
                    }
                  </ListUnits>
                </List>
              </>
            }
          />
          <SecurityCard
            title="NOSSOS LABORATÓRIOS"
            description=" Os laboratórios de genética do Hospital Israelita Albert Einstein contam com especialistas em Bioinformática e Desenvolvimento de Sistemas. Tudo isso para proporcionar análises mais seguras, com a precisão necessária para guiar tratamentos e diagnósticos de pacientes."
            icon={<IconLab />}
            buttonTxt="Saiba mais"
            id="btn-006"
            modalChildren={
              <>
                <List>
                  <ul>
                    <li>
                      <p>
                        Utilizamos tecnologia de sequenciamento de segunda
                        geração para realizar a análise do DNA. O processo é em
                        grande parte automatizado, o que minimiza o risco de
                        falhas humanas, aumentando a assertividade.
                      </p>
                    </li>
                    <li>
                      <p>
                        A sua amostra de sangue deverá ser coletada em nossos laboratórios do Hospital Israelita Albert Einstein. Veja as unidades:
                      </p>
                    </li>
                  </ul>
                  <ListUnits>
                    {
                      Object.keys(unitsPredictaByUF).map(key => (
                        <div>
                          <h3>{unitsPredictaByUF[key].uf}</h3>
                          <ul>
                            {unitsPredictaByUF[key].units.map((unity: any) => (
                              <li>
                                <p>{unity}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))
                    }
                  </ListUnits>
                </List>
              </>
            }
          />

          <SecurityCard
            title="PROFISSIONAIS"
            description="Além da alta tecnologia aplicada à medicina genética, o Hospital Israelita Albert Einstein conta com uma equipe formada por profissionais altamente capacitados e especializados na área para trazer mais segurança e assertividade a todo o processo. Conheça um pouco mais do time de genética do Einstein."
            icon={<IconDoc />}
            buttonTxt="Saiba mais"
            id="btn-007"
            modalChildren={
              <Slider {...settings}>
                <DescriptionCard
                  name="João Bosco de Oliveira"
                  image={Doutor1}
                  description="Dr. João Bosco de Oliveira é formado em Medicina pela Universidade de Pernambuco (UPE). Fez residência em Clínica Médica pela Universidade Federal de Pernambuco (UFPE) e especialização e Pós-Doutorado em Imunologia clínica e laboratorial pelo National Institutes of Health, EUA. Também tem Doutorado em Patologia Clínica pela Universidade de São Paulo e Ph.D. em Imunologia Experimental pela Universidade de Amsterdã. <br/><br/>Dr. Bosco foi co-Diretor do setor de Genética e Imunologia do Departamento de Medicina Laboratorial do National Institutes of Health, nos EUA. <br/><br/>Atualmente é Gerente Médico do Dept. de Medicina Laboratorial e Co-Coordenador Médico do Programa de Medicina de Precisão, Hospital Israelita Albert Einstein."
                />
                <DescriptionCard
                  name="Fernanda Teresa de Lima"
                  image={Doutor2}
                  description="Doutora em Morfologia pela Universidade Federal de São Paulo. Especialização em Educação em Saúde pela UNIFESP e Educação Continuada e Permanente em Saúde (Instituto Israelita de Ensino e Pesquisa Albert Einstein). <br/><br/>Possui graduação em Medicina pela Universidade Federal de Santa Catarina. Formação em Melhoria Contínua de Processos, sendo certificada com Lean Belt na metodologia Lean Six Sigma pelo Hospital Israelita Albert Einstein. Chefe do Setor de Oncogenética da Disciplina de Mastologia do Departamento de Ginecologia da Universidade Federal de São Paulo e responsável pelo Ambulatório de Oncogenética do Instituto de Oncologia Pediátrica - Grupo de Apoio ao Adolescente e à Criança com Câncer da Universidade Federal de São Paulo. Médica do Centro de Aconselhamento Genético do Hospital Israelita Albert Einstein."
                />
                <DescriptionCard
                  name="Renata Moldenhauer Minillo"
                  image={Doutor3}
                  description="Graduação em Medicina pela Universidade de Santo amaro (UNISA) e Residência em Genética Médica pela Universidade Federal de São Paulo (UNIFESP),Título de Especialista pela Sociedade Brasileira de Genética Médica e Genômica (SBGM), Mestrado em Ciências Médicas pelo Departamento de Biologia Estrutural e Funcional da Universidade Federal de São Paulo (UNIFESP). <br/><br/>Atualmente, é Médica Geneticista do Hospital Israelita Albert Einstein e do Projeto Genomas Raros – PROADI-SUS, atuando no diagnóstico clínico e molecular de doenças genéticas."
                />
                <DescriptionCard
                  name="Priscila de Freitas Teixeira da Silva"
                  image={Doutor5}
                  description="Graduação em enfermagem pela faculdade Estácio. Pós-graduação em pediatria e neonatologia pela Faculdade Israelita de Ciência da Saúde Albert Einstein. Enfermeira do Centro de Medicina Genômica Einstein com qualificação em Aplicações Diagnósticas da Genética Molecular."
                />
              </Slider>
            }
          />

          <SecurityCard
            title="SOMOS O EINSTEIN"
            description=" Inaugurado oficialmente em 1971, o Hospital Israelita Albert Einstein é referência em tratamentos, com tecnologia de ponta e atendimento humanizado. Oferecer excelência no atendimento e na geração de conhecimento estão dentro da missão do Hospital. "
            icon={<IconGene />}
            buttonTxt="Saiba mais"
            id="btn-008"
            modalChildren={
              <List>
                <ul>
                  <li>
                    <p>
                      Inaugurado oficialmente em 1971, o Hospital Israelita
                      Albert Einstein sempre foi referência em tratamentos,
                      com tecnologia de ponta e atendimento humanizado.
                      Oferecer excelência no atendimento e na geração de
                      conhecimento estão dentro da missão do Hospital.
                    </p>
                  </li>
                  <li>
                    <p>
                      Por sete vezes consecutivas o Einstein foi considerado,
                      pela revista América Economia Intelligence, o melhor
                      hospital da América Latina. O ranking considera aspectos
                      de segurança e dignidade do paciente, capital humano,
                      capacidade de atendimento, eficiência, prestígio e
                      gestão do conhecimento.
                    </p>
                  </li>
                  <li>
                    <p>
                      Tanta excelência é fruto de processos elaborados com
                      cuidado e implantados com toda a equipe. O Sistema
                      Einstein de Qualidade (SEQ) é um conjunto de programas
                      gerenciais consagrados que sistematiza o planejamento, a
                      formulação e a operacionalização dos processos
                      assistenciais e de apoio, necessários para garantir a
                      excelência nos serviços prestados. O objetivo é
                      identificar oportunidades de melhorias e utilizar a
                      ferramenta adequada para correção antes que o problema
                      se transforme em uma ocorrência.
                    </p>
                  </li>
                </ul>
              </List>
            }
          />
        </SecuritySection>

        <FaqSection id="ajuda">
          <h2>Ficou alguma dúvida?</h2>
          <p>Confira aqui algumas perguntas frequentes: </p>
          <AccordionWrapper>
            {accordionItems !== undefined && accordionItems?.length !== 0
              ? (
                accordionItems.map((item, index) => {
                  return (
                    <Accordion
                      btnColor="#00B193"
                      key={index}
                      title={String(item.title)}
                    >
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </Accordion>
                  );
                })
              )
              : (
                <div className="span">Nenhum resultado encontrado...</div>
              )}
          </AccordionWrapper>
          <button id="btn-009" onClick={allItems} className="btn">
            {' '}
            {allFaq ? 'Ver menos' : 'Todas as dúvidas'}
          </button>
        </FaqSection>

        <CompromiseSection>
          <LogoEinstein />
          <h4>COMPROMISSO E EXCELÊNCIA!</h4>
          {/* <p>
            Nossos processos seguem severamente a LGPD genética
            <br />
            para proteger e guardar os dados do seu DNA!
          </p> */}
          <div>
            <img src={FactImg} />
            <img src={JciImg} />
            <img src={WestchesterImg} />
          </div>
        </CompromiseSection>

        <ButtonWhatsapp phone="551136202550" />
      </Content>
      <Footer></Footer>
    </>
  );
}
